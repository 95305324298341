<template>
    <div>
        <!-- <button @click="reInit">Reinit</button>
        <button @click="stopStream">Close stream</button> -->
    </div>
</template>

<style scoped lang="scss">
    div{
        position:absolute;
        top:0.5rem;
        left:0.5rem;

        button{
            display:inline-block;
        }
    }
</style>

<script>
    import Web3 from 'web3';
    import Socket from '@/app/sockets/Socket';

    export default {
        props: {
            url: {
                type: String,
                default: ''
            },

            abi: {
                type: Array,
                default: []
            },

            address: {
                type: String,
                default: ''
            },

            preventSocketsOpening: {
                type: Boolean,
                default: false
            }
        },

        data()
        {
            return {
                prevent: false
            };
        },

        mounted()
        {
            this.prevent = this.preventSocketsOpening;
            if( !this.prevent )
            {
                this.reInit();
            }
        },

        beforeDestroy()
        {
            if( this.contract )
            {
                this.contract.clearSubscriptions();
            }

            if( this.socket )
            {
                this.socket.close();
            }
        },

        methods: {
            reInit()
            {
                if( this.prevent )
                {
                    return;
                }

                if( !this.prevent )
                {
                    this.web3 = new Web3( Web3.givenProvider || new Web3.providers.WebsocketProvider( this.url ) );

                    console.log( this.web3 );

                    this.contract = new this.web3.eth.Contract( this.abi, this.address );

                    console.log( this.contract );

                    this.contract.currentProvider.on( 'open', this.openSubscriptions );
                    this.contract.currentProvider.on( 'end', e => console.error( 'WS End', e ) );

                    // this.wsprovider = new Web3.providers.WebsocketProvider('wss://mainnet.infura.io/ws')
                    // this.wsprovider.on('end', e => console.error('WS End', e));

                    // this.contract.currentProvider.

                    // this.contract.getPastEvents( 'allEvents', {
                    //     fromBlock: 0,
                    //     toBlock: 'latest'
                    // })
                    // .then( events =>
                    // {
                    //     console.log( 'past events', events );
                    // });

                    // this.socket = new Socket( this.url, { messageCallback: this.onMessage, onOpenCallback: this.emitReady });
                }

                // this.socket = new Socket( this.url, { messageCallback: this.onMessage, onOpenCallback: this.emitReady });
            },

            logStatus()
            {
                console.log( 'contract.currentProvider', this.contract.currentProvider.connection.readyState );
                console.log( this.contract.currentProvider.send );
            },

            allowConnections()
            {
                this.prevent = false;
            },

            preventConnections()
            {
                this.prevent = true;
            },

            openSubscriptions()
            {
                // get blocks
                console.log( 'send!' );
                let options = {

                }
                this.contract.events.allEvents( options, async ( error, event ) =>
                {
                    if( error )
                    {
                        console.warn( 'error', error );
                        return;
                    }

                    console.log( 'Incoming:', event.event, event.transactionHash );

                    /* event.event =
                        Transfer
                        StakeStart
                        StakeEnd
                        Approval
                     */

                    if( event.event === 'StakeStart' )
                    {
                        console.log( 'staked amount:', this.web3.utils.toBN( event.raw.topics[0] ) );
                        console.log( 'staked amount:', this.web3.utils.toBN( event.raw.topics[1] ) );
                        console.log( 'staked amount:', this.web3.utils.toBN( event.raw.topics[2] ) );

                        console.log( event );
                    }
                });
                // this.send( `{ "jsonrpc": "2.0", "id": 1, "method": "subscribe", "params": ["address:logs", { "address": "${this.address}" }]}` );
            },

            subscribe( pStr )
            {
                // if( !this.socket )
                // {
                //     this.reInit();
                // }

                console.log( this.contract.currentProvider.supportsSubscriptions() );

                // send gebruiken en dan in send de subscription meegeven
            },

            send( pData )
            {
                console.log( pData );
                this.contract.currentProvider.send( pData, this.onMessage );
            },

            pingBlock()
            {
                this.subscribe( '{ "op": "ping_block" }' );
            },

            startTxStream()
            {
                this.subscribe( '{ "op": "unconfirmed_sub" }' );
            },

            getQuote()
            {
                this.subscribe( '{ "op": "subscribe", "args": ["quote:XBTUSD"] }' );
            },

            onMessage( pData )
            {
                console.log( 'message received', pData );
                // this.$emit( 'message', pData.data );
            },

            stopStream()
            {
                this.socket.close();
                this.socket = null;
            },

            emitReady()
            {
                console.log
                this.$emit( 'ready' );
            }
        }
    };
</script>
