<template>
    <div>
        <h1>Mr. Ynk Frontnd CLI</h1>
        <p>Type: {{ appType }}</p>
        <v-socket @ready="stream.ready = true" :url="stream.socketUrl" :abi="stream.abi" :address="stream.address" :preventSocketsOpening="stream.preventAutoOpen" ref="stream" @message="onMessage"></v-socket>

        <div class="button-wrapper">
            <button @click="openStream">Open stream, get Contract</button>
            <button @click="getStatus">Get status</button>
            <button @click="trySub">Try subscription</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Web3 from 'web3';
    import EthSocket from '@/app/components/EthSocket';

    import HexAbi from '@/app/data/HexAbi';

    const PROJECTID = '55705ca9697a478d922cde04909c6e54';
    const CONTRACTABI = HexAbi;
    const CONTRACTADDRESS = '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39';

    export default {
        components: {
            'v-socket': EthSocket
        },

        data()
        {
            return {
                appType: 'clean',

                stream: {
                    name: 'Ethereum activity',
                    socketUrl: 'wss://mainnet.infura.io/ws/v3/' + PROJECTID,
                    abi: CONTRACTABI,
                    address: CONTRACTADDRESS,
                    preventAutoOpen: true,
                    ready: false
                },
            };
        },

        watch: {
            'stream.ready': function( pVal )
            {
                if( pVal && this.stream.ready )
                {
                    this.subscribeToContract();
                }
            }
        },

        mounted()
        {
        },

        methods: {
            openStream()
            {
                this.$refs.stream.allowConnections();
                this.$refs.stream.reInit();
            },

            trySub()
            {
                // this.$refs.stream.getActivity();
                this.$refs.stream.openSubscriptions();
            },

            subscribeToContract()
            {

            },

            getStatus()
            {
                this.$refs.stream.logStatus();
            },

            onMessage( pData )
            {
                console.log( pData );
            }
        }
    };
</script>
